<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="text-center mt-5 mb-3"
        cols="12"
      >
        <Heading :title="title" />
        <div v-if="change_success">
          Vous allez être redirigé vers le tableau de bord.
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        offset-sm="2"
      >
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <v-row>
              <v-col v-show="showChangePasswordInputs">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|password"
                  vid="password"
                >
                  <v-text-field
                    id="password"
                    ref="password"
                    v-model="password"
                    name="password"
                    type="password"
                    :label="$t('resetPassword.PASSWORD')"
                    :error="errors.length > 0"
                    :error-messages="errors[0]"
                    autocomplete="off"
                  />
                  <ul>
                    <li :class="minimumRule">
                      8 caractères minimum
                    </li>
                    <li :class="upCaseRule">
                      Au moins une majuscule
                    </li>
                    <li :class="numberRule">
                      Au moins un chiffre
                    </li>
                    <li :class="specialCharRule">
                      Au moins un caractère spéciale ([],;#*=|~?)
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col v-show="showChangePasswordInputs">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|confirmed:password"
                >
                  <v-text-field
                    id="confirmPassword"
                    v-model="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    :label="$t('resetPassword.CONFIRM_PASSWORD')"
                    :error="errors.length > 0"
                    :error-messages="errors[0]"
                    autocomplete="off"
                  />
                </ValidationProvider>
              </v-col>
              <v-col
                v-show="showChangePasswordInputs"
                class="text-center mt-5"
              >
                <SubmitButton
                  :button-text="$t('resetPassword.CHANGE_PASSWORD')"
                />
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-col>
      <ErrorMessage />
      <SuccessMessage />
    </v-row>
  </v-container>
</template>

<script>
  import router from '@/router'
  import { mapActions } from 'vuex'
  import validator from '@/plugins/vee-validate'

  export default {
    metaInfo () {
      return {
        title: this.$store.getters.appTitle,
        titleTemplate: `${this.$t('resetPassword.TITLE')} - %s`,
      }
    },
    data () {
      return {
        id: this.$route.params.id,
        password: '',
        confirmPassword: '',
        title: `${this.$t('resetPassword.TITLE')}`,
        change_success: false,
        upCaseRule: '',
        numberRule: '',
        specialCharRule: '',
        minimumRule: '',
      }
    },
    methods: {
      ...mapActions(['resetPassword']),
      async submit () {
        await this.resetPassword({
          id: this.id,
          password: this.password,
        })
        this.title = 'Mot de passe modifié.'
        this.change_success = true
        setTimeout(() => {
          router.push({ path: '/' })
          this.$store.state.resetPassword.showChangePasswordInputs = true
        }, 2000)
      },
    },
    computed: {
      showChangePasswordInputs () {
        return this.$store.state.resetPassword.showChangePasswordInputs
      },
    },
    created () {
      if (this.$store.state.auth.isTokenSet) {
        // router.push({ name: '' })
      }
      validator.extend('password', value => {
        let valid = true

        // Longueur
        if (value.length >= 8) {
          this.minimumRule = 'rule-valid'
        } else {
          this.minimumRule = 'rule-invalid'
          valid = false
        }

        // Majuscule
        if (value.match(/[A-Z]/g) !== null) {
          this.upCaseRule = 'rule-valid'
        } else {
          this.upCaseRule = 'rule-invalid'
          valid = false
        }

        // Chiffre
        if (value.match(/[0-9]/g) !== null) {
          this.numberRule = 'rule-valid'
        } else {
          this.numberRule = 'rule-invalid'
          valid = false
        }

        // Caractère spécial
        if (value.match(/[@[!"#()\]*/:;=|~\]?]/g) !== null) {
          this.specialCharRule = 'rule-valid'
        } else {
          this.specialCharRule = 'rule-invalid'
          valid = false
        }
        return valid
      })
    },
  }
</script>
<style>
  .rule-valid {
    color: #4caf50;
  }
  .rule-invalid {
    color: #ff5252;
  }
</style>
